import React ,{useRef} from 'react'
import{ init} from 'emailjs-com';
import emailjs from 'emailjs-com';
init("user_N5l2JLrmfxmyFxKpZLedN");

export default function Contact() {
    const form = useRef();
    const sendEmail= (e)=>{
        e.preventDefault();
        emailjs.sendForm('service_uf6lpkr', 'template_b9pw5gh', form.current, 'user_N5l2JLrmfxmyFxKpZLedN')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    }
    return (
        <div>
            <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
          
        </div>
      </div>

      <div>
        <iframe style={{border:0, width: "100%", height: 350}} src="https://maps.google.com/maps?q=Shri%20Murlidhar%20Memorial%20Nursing%20Home%20NH%2031%2C%20Barh%2C%20Bihar%20803213&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" allowfullscreen></iframe>
      </div>

      <div class="container">
        <div class="row mt-5">

          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>
                Shri Murlidhar Memorial Nursing Home <br/>
          Opp. Dak Bangla. NH-31, Barh, Patna, Bihar<br/>
          India</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>gitaeye@gmail.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 91557 197 17</p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

            <form action="/" ref={form} onSubmit={sendEmail}  class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>

          </div>

        </div>

      </div>
    </section>
        </div>
    )
}
