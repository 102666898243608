import React from 'react'

export default function Services() {
    return (
        <div>
             <section id="services" class="services">
      <div class="container">

        <div class="section-title">
          <h2>Services</h2>
          <p>We are providing various services in eye care</p>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-eye"></i></div>
              <h4><a href="">Vision Test</a></h4>
              <p>Perform eye exams and Vision tests and provide results</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-pills"></i></div>
              <h4><a href="">Vision Condition</a></h4>
              <p>Treat conditions like myopia, hypermetropia and astigmatism</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-hospital-user"></i></div>
              <h4><a href="">Contact Lens</a></h4>
              <p>Prescribe fit and eye glasses and contact lenses</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-dna"></i></div>
              <h4><a href="">Threapy</a></h4>
              <p> we Provide low vision aids and vision threapy with consultation</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-wheelchair"></i></div>
              <h4><a href="">Cataract Services</a></h4>
              <p>Cataract can be diagnosed with an eye checkup done by a qualified optometrist or ophthalmologist</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
            <div class="icon-box">
              <div class="icon"><i class="fas fa-notes-medical"></i></div>
              <h4><a href="">Treatment</a></h4>
              <p>We detect and provide treatment of diseases,insuries and disorders related to eye.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
        </div>
    )
}
