import React from 'react'

export default function Notices() {
    return (
        <div>
            <section id="testimonials" class="testimonials">
      <div class="container">
            <h1>Notice</h1>
        <div class="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                 
                  <h3>#JYOTIMALA संकल्प</h3>
                  
                  <p>
                 20 oct 2021 <br/>
                  "सफ़र में बुज़ुर्गों का आशीर्वाद समय समय पर मिलता रहे तो यात्री के क़दम सहज ही तेज़ चलने लगते हैं ।"
#SRIMMNH श्री मुरलीधर मेमोरीयल नर्सिंग होम के आँख विभाग #GITAEYE के #JYOTIMALA संकल्प शृंखला के 1️⃣0️⃣8️⃣ कैम्प के संकल्प का एक सौभाग्य पूर्ण पड़ाव, आज बाढ़ प्रखंड के दाहौर ग्राम में श्री भरत प्रसाद सिंह चाचाजी के दालान पर दाहौर विकास समिति के नवयुवक बंधुओं द्वारा 2️⃣0️⃣वाँ कैम्प का आयोजन आज दिनाँक2️⃣0️⃣अक्टूबर को सम्पन्न हुआ । जिसमें कुल 8️⃣0️⃣ ग्रामीणों का निःशुल्क आँख जाँच कर यथा योग्य इलाज का सलाह प्रसिद्ध नेत्र विशेषज्ञ सुश्री गीतांजली आष्टा जी के द्वारा  दिया गया! #JYOTIMALA संकल्प श्रृंखला में अभी तक कुल 1️⃣3️⃣3️⃣7️⃣ मरीजों को मुफ्त सलाह दी गई है!
                   
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                 
                  <h3>#JYOTIMALA संकल्प</h3>
                  
                  <p>
                  8 oct 2021 <br/>
                  साधना में साधन का अभाव बाधक नही होता है,इसी कथन को चरितार्थ करते हुए,एक अर्धनिर्मित भवन में आज दिनाँक 8️⃣ अक्टूबर को #SRIMMNH श्री मुरलीधर मेमोरियल नर्सिंग होम के आँख विभाग #GITAEYE द्वारा अथमलगोला प्रखंड के जमालपुर गाँव में #GITAEYE के #JYOTIMALA संकल्प श्रृंखला का1️⃣9️⃣वां मुफ्त आँख जाँच शिविर का आयोजन किया गया।जिसमें 6️⃣4️⃣ग्रामवासियों का मुफ्त आँख जाँच कर उचित सलाह नेत्र विशेषज्ञ सुश्री गीतांजली आष्टा जी के द्वारा दिया गया।अभी तक शिविरों के माध्यम से कुल 1️⃣2️⃣5️⃣7️⃣ लोगों की मुफ्त आँख जाँच की गई है।
                 
                  </p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                 
                  <h3>#JYOTIMALA संकल्प</h3>
                  
                  <p>
                   26 sept 2021 <br/>
                  जब मन में सेवा भाव जगा 
तो साथी मिल ही जाते हैं। 
इसी पंक्ति को चरितार्थ करते हुए विश्व हिंदू परिषद भी #GITAEYE के संकल्प में सहभागी बन गए। इसी क्रम में आज #SRIMMNH श्री मुरलीधर मेमोरियल नर्सिंग होम के आँख विभाग #GITAEYE द्वारा आज दिनाँक 26 सितंबर को #JYOTIMALA संकल्प श्रृंखला का 1️⃣8️⃣वां मुफ्त आँख जाँच शिविर का आयोजन बाढ़ शहर के वार्ड नं.13 में आयोजित किया गया, जिसमें6️⃣5️⃣मरीजों का मुफ्त जाँच सुश्री गीतांजली आष्टा जी द्वारा किया गया।अभी तक मुफ्त शिविर में कुल1️⃣1️⃣9️⃣3️⃣मरीजों की जाँच की गई। सभी आयोजक सदस्यों का बहुत बहुत आभार।
                   
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                 
                  <h3>#JYOTIMALA संकल्प</h3>
                  24 sept 2021 <br/>
                  <p>
                    
                  आज दिनाँक 24 सिंतबर को बाढ़ प्रखण्ड के भटगॉंव गाँव में  #JYOTIMALA (ज्योतिमाला) श्रृंखला के अंतर्गत #SRIMMNH श्री मुरलीधर मेमोरीयल नर्सिंग होम के आँख विभाग गीता आईं #GITAEYE के द्वारा मुफ़्त नेत्र जाँच शिविर लगाया गया। जिसमें 6️⃣9️⃣ मरीज़ों को देख कर सलाह दी गयी। यह इस शृंखला का 1️⃣7️⃣ वाँ शिविर था! अभी तक इस #JYOTIMALA श्रृंखला में कुल1️⃣1️⃣2️⃣8️⃣ मरीजों को मुफ्त जाँच एवं सलाह दी गयी है!
                   
                  </p>
                </div>
              </div>
            </div>


          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div>
    </section>
        </div>
    )
}
