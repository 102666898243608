import React from 'react'
import Hero from '../components/Hero'
import Why from '../components/Why'
import About from '../components/About'
import Count from '../components/Count'
import Services from '../components/Services'
import Notices from '../components/Notices'
import Gallery from '../components/Gallery'
import Contact from '../components/Contact'
export default function Home() {
    return (
        <div>
            <Hero/>
            <About/>
            <Notices/>
            <Why/>
            <Count/>
            <Services/>
            <Gallery/>
            <Contact/>
        </div>
    )
}
