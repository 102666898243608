import React from 'react'

export default function Hero() {
    return (
        <div>
              <section id="hero" class="d-flex align-items-center">
    <div class="container">
      <h1>Take Care Of Your <br/> Eye Health Here</h1>
      <h4>Genric inclusive Therapy and Assessment of Eye</h4>
     
      <a target="_balnk" href="https://api.whatsapp.com/send?phone=+91 91557 197 17&text=Hello" class="btn-get-started scrollto">Get free consultation</a>
    </div>
  </section>
        </div>
    )
}
