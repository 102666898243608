import React from "react";

export default function Cataract() {
  return (
    <div>
      <section id="about" class="about">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
              <a
                href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
                class="glightbox play-btn mb-4"
              ></a>
            </div>

            <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
              <h3>Cataract Services</h3>
              <p>
                A cataract is the second leading cause of preventable blindness.
                It is responsible for 662% of blindness caused in people above
                50 years of age according to the National BLindness and visual
                impairment survey India 2015-2019.
              </p>
              <p>
                A cataract is the clouding of the natural lens which does not
                allow light to pass through. It thus, causes blurred vision and
                the person is unable to see clearly.
              </p>

              <div class="icon-box">
                <p class="description">
                  Sign and Symptoms can range to any of the following:- 1.
                  Bluring of vision 2. Senitivity to light 3. Unble to recognize
                  face at a distance 4. Difficulty in dim light condition
                </p>
              </div>
              <p>
                {" "}
                Diagnosis:- Cataract can be diagnosed with an eye checkup done
                by a qualified optometrist or ophthalmologist{" "}
              </p>
              <p>
                Treatment:- Very early cataracts, may be helped by using
                nutritional supplements and the speed of the cataract can be
                arrested. For immature or mature cataract surgery is the only
                option. The surgeon will remove the cataractous lens and place a
                new lens or IOL in your eye. There are many techniques to do
                this surgery, the best available option so the fan is the
                phacoemulsification or phase technique by which the lens is
                broken into pieces and removed out of the eye by a very small
                cut that does not need any stitch. we at Gitaeye have the baest
                phaco machine manufactured by oertli switzland. The machine uses
                the best technique to remove the cataract which has a minimal
                impact on the eye.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
