import React from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
    return (
        <div>
            <footer id="footer">

<div class="footer-top">
  <div class="container">
    <div class="row">

      <div class="col-lg-3 col-md-6 footer-contact">
        <h3>Gitaeye</h3>
        <p>
          Shri Murlidhar Memorial Nursing Home <br/>
          Opp. Dak Bangla. NH-31, Barh, Patna, Bihar<br/>
          India <br/><br/>
          <strong>Phone:</strong> +91 91557 197 17<br/>
          <strong>Email:</strong> gitaeye@gmail.com<br/>
        </p>
      </div>

      <div class="col-lg-2 col-md-6 footer-links">
        <h4>Useful Links</h4>
        <ul>
          <li><i class="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#services">Services</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#testimonials">Notices</a></li>
          <li><i class="bx bx-chevron-right"></i> <Link to="/terms"> <a>Terms of service</a> </Link></li> 
          <li><i class="bx bx-chevron-right"></i> <Link to="/privacy"> <a>Privacy policy</a> </Link></li>
        </ul>
      </div>

      <div class="col-lg-3 col-md-6 footer-links">
        {/* <h4>Our Services</h4>
        <ul>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
          <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
        </ul> */}
      </div>

      <div class="col-lg-4 col-md-6 footer-newsletter">
        <h4>Join Our Newsletter</h4>
        <p>Subcribe to our news letter for your healthy eyes.</p>
        <form name="news-letter" method="POST" data-netlify="true" >
          <input type="email" name="email"/><input type="submit" value="Subscribe"/>
        </form>
      </div>

    </div>
  </div>
</div>

<div class="container d-md-flex py-4">

  <div class="me-md-auto text-center text-md-start">
    <div class="copyright">
      &copy; Copyright <strong><span>Gitaeye</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
      
      Designed by <a  target="_blank" href="https://twitter.com/konfav/">Ritik Sinha</a>
    </div>
  </div>
  <div class="social-links text-center text-md-right pt-3 pt-md-0">
    <a href="https://twitter.com/gita_eye" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="https://www.facebook.com/gitaneye/" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="https://www.instagram.com/gita.eye/" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="https://twitter.com/gita_eye" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
  </div>
</div>
</footer>
            
        </div>
    )
}
