import React from 'react'

export default function About() {
    return (
        <div>
               <section id="about" class="about">
      <div class="container-fluid">

        <div class="row">
          <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
            <a href="https://www.youtube.com/watch?v=zQE_XY-h95k" class="glightbox play-btn mb-4"></a>
          </div>

          <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>My Story</h3>
            <p>Way back in 1997 when I joined optometry degree, I was told that this is a very good choice as you can have a stand-alone practice…. Years passed by and less did I understand its meaning? During my career path I was working for a Stand-alone Optometry clinic and it was while working there that the idea of having a practice of my own sparked.</p>

            <div class="icon-box">
              <p class="description">In due course of life I met another likeminded person and with the courage and strength imparted by him, the whole idea of Gita Eye took birth again. So it took almost 16 years of mental preparation, to come-up with the actual set-up of Gita Eye.</p>
            </div>

            <div class="icon-box">
              <p class="description">Half of the people are suffering with disease or specifically eye disease due to misdiagnosis or partial diagnosis, there came up our belief of “SAHI JAANCH MATLAB SAHI ILAAJ” if the diagnosis is correct then the problem will definitely be well treated.</p>
            </div>

            <div class="icon-box">
        
              <p class="description">Our goal is to create a state-of-the-art facility of affordable eye care reachable for all. With a depth of knowledge laden with experience of twenty years and support of latest technology we bring quality eye care close to your home, so that you don’t have to travel long to get your eye treated. We constantly strive to implement new ideas and technologies to meet the unmet needs and think ahead of times.</p>
            </div>
            <div class="icon-box">
              <p class="description">We strongly believe in practicing honesty, impartiality and fairness in all times and we strictly adhere to ethical values and high moral standards.</p>
            </div>
            
          </div>
        </div>

      </div>
    </section>
        </div>
    )
}
