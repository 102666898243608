import React from 'react'

export default function Why() {
    return (
        <div>
            <section id="why-us" class="why-us">
      <div class="container">

        <div class="row">
          <div class="col-lg-4 d-flex align-items-stretch">
            <div class="content">
              <h3>Preventable Blindness</h3>
              <p>
              Blindness is a state where we label a person a blind when they cannot see the light or unable to perform activites of daily living without the help of another person.
              </p>
              <div class="text-center">
                <a href="#" class="more-btn">90% of the blindness is preventable. <i class="bx bx-chevron-right"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-8 d-flex align-items-stretch">
            <div class="icon-boxes d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-xl-4 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                    <i class="bx bx-capsule"></i>
                    <h4>cataract surgery</h4>
                    <p>Cataract surgery is a procedure to remove the lens of your eye and, in most cases, replace it with an artificial lens. Normally, the lens of your eye is clear. A cataract causes the lens to become cloudy, which eventually affects your vision</p>
                  </div>
                </div>
                <div class="col-xl-4 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                    <i class="bx bx-glasses-alt"></i>
                    <h4>prescribing glasses</h4>
                    <p>we try to treat preventable blindness in all ages. By doing these treatments ve directly impact that previous life, improve the quality of life of the family and hence the community at large. </p>
                  </div>
                </div>
                <div class="col-xl-4 d-flex align-items-stretch">
                  <div class="icon-box mt-4 mt-xl-0">
                    <i class="bx bx-award"></i>
                    <h4>Mission</h4>
                    <p>To build a state of the art cost-effective, innovation based institute which is able to reach all the needy patients and be able to improve their lives!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
        </div>
    )
}
