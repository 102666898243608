import React from 'react'
import logo from '../assets/img/logo.png'
import { Link ,useLocation } from 'react-router-dom'
export default function Navbar() {
  const location =  useLocation();
  const path= location.pathname;
    return (
        <div>
            <div id="topbar" class="d-flex align-items-center fixed-top">
    <div class="container d-flex justify-content-between">
      <div class="contact-info d-flex align-items-center">
        <i class="bi bi-envelope"></i> <a href="mailto:contact@example.com">gitaeye@gmail.com</a>
        <i class="bi bi-phone"></i> +91 91557 197 17
      </div>
      <div class="d-none d-lg-flex social-links align-items-center">
        <a href="https://twitter.com/gita_eye" target="_blank" class="twitter"><i class="bi bi-twitter"></i></a>
        <a href="https://www.facebook.com/gitaneye/" target="_blank" class="facebook"><i class="bi bi-facebook"></i></a>
        <a href="https://www.instagram.com/gita.eye/" target="_blank" class="instagram"><i class="bi bi-instagram"></i></a>
        <a href="https://twitter.com/gita_eye" target="_blank" class="linkedin"><i class="bi bi-linkedin"></i></a>
      </div>
    </div>
    </div>
  
            <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">
             <h1 class="logo me-auto"> <Link to="/"> <img src={logo} alt="Gitaeye" style={{width:"70%",height:"auto"}}/></Link></h1>
              <nav id="navbar" class="navbar order-last order-lg-0">
                {path==="/"?(<>
                  <ul>
          <li> <Link to="/">  <a class="nav-link scrollto" href="#hero">Home</a> </Link></li>
          <li><a class="nav-link scrollto" href="#about">About</a></li>
          <li><a class="nav-link scrollto" href="#services">Services</a></li>
          <li><a class="nav-link scrollto" href="#testimonials">Notices</a></li>
          <li><a class="nav-link scrollto" href="#gallery">Gallery</a></li>
          {/* <li class="dropdown"><a href="#"><span>Campus</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li> */}
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
                
                </>):(<></>)}
       
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      </div>
      </header>
        </div>
    )
}
